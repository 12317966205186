import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/var/lib/jenkins/workspace/jelly-bdc-prod-scheduled/www/bdc/src/templates/default-markdown/index.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`These Ad Guidelines (the “Guidelines”) apply to the websites owned and operated by BuyerZone.com, LLC d/b/a Business.com and its affiliates including but not limited to: BuyerZone; Business News Daily and Business.com (the “Business.com Websites”).`}</p>
    <p>{`Business.com has the right, at any time and for any reason, to: (a) refuse any advertising or advertisers; (b) make exceptions to these Guidelines on a case-by-case basis; (c) take down ads it deems inappropriate; and/or (d) make changes to these Guidelines.`}</p>
    <h2>{`Ad Guidelines Policy`}</h2>
    <p>{`Final and ongoing approval of all creative materials is at the sole discretion of Business.com and Business.com reserves the right to re-evaluate previously approved creative materials at any time to ensure ongoing compliance with these guidelines, ensure an optimal user experience, or in response to significant negative feedback from our users.`}</p>
    <h2>{`General Standards`}</h2>
    <ul>
      <li parentName="ul">{`All creative must meet the Business.com technical ad specifications then in effect. These specifications will be provided by Business.com upon request.`}</li>
      <li parentName="ul">{`All creative must function uniformly on Mac and PC platforms as well as multiple versions of Chrome, Internet Explorer, Firefox, Safari and Mozilla`}</li>
      <li parentName="ul">{`Streaming audio and video experiences within ad units are allowed, as long as the audio portion starts only when initiated by the user via a click.`}</li>
      <li parentName="ul">{`Pop-up and pop-under advertisements are not allowed.`}</li>
      <li parentName="ul">{`Advertisements cannot force an alert, a dialog or an installation of a plug-in or software.`}</li>
      <li parentName="ul">{`Advertisements may not include non-functioning or fake “Close” buttons – all Close buttons must function properly.`}</li>
      <li parentName="ul">{`Advertisements must correctly identify the advertiser and/or product or services being promoted and must align with the landing page associated with the advertisement- “bait-and-switch” advertisements are not allowed.`}</li>
      <li parentName="ul">{`Advertisements containing any Business.com trademark or logo is not allowed without permission.`}</li>
      <li parentName="ul">{`Advertisements that require or allow for the submission of personal information must include a link to advertiser’s privacy policy.`}</li>
      <li parentName="ul">{`Ads must not promote the sale or use of the following: (i) illegal, prescription, or recreational drugs; (ii) tobacco products and related paraphernalia; (iii) weapons, ammunition, or explosives; or (iv) adult products or services, including pornographic, violent or obscene content. Business.com reserves the right to preserve the user experience of its sites, and reserves the right to remove any advertisement which is deemed annoying, inappropriate and/or harmful to our users at any time.`}</li>
    </ul>
    <h2>{`Look and Feel`}</h2>
    <ul>
      <li parentName="ul">{`Advertisements cannot mislead the user. For example, they may not mimic or resemble Windows/Mac/Unix dialogue boxes, error messages, or the like and cannot be designed to make it look as though there is an error on the webpage or the webpage or site is broken.`}</li>
      <li parentName="ul">{`Advertisements cannot resemble the visual style of the content area or Business.com Site on which the advertisement is displayed. This includes but is not limited to using colors, marks, fonts or icons explicitly associated with the applicable Business.com Site.`}</li>
      <li parentName="ul">{`No strobing on animated GIFs or Flash. Business.com has the right to reject any ad that is distracting to the normal reading of page content.`}</li>
      <li parentName="ul">{`Advertisements cannot contain graphics that simulate interactivity (i.e., drop down menus, search boxes, etc.) without that functionality actually existing. Fake or simulated HTML interactivity is not allowed.`}</li>
      <li parentName="ul">{`Advertisements cannot mimic news headlines in design, tone, 3rd person sentence structure, or topic.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      